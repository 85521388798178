import React from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";
import AuthContextProvider from "./contexts/AuthContext";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const localUser = JSON.parse(localStorage.getItem("currentUser")) || {};

// Containers
const AuthenticatedLayout = React.lazy(() =>
  import("./containers/AuthenticatedLayout/AuthenticatedLayout")
);
const UnAuthenticatedLayout = React.lazy(() =>
  import("./containers/UnAuthenticatedLayout/UnAuthenticatedLayout")
);

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
// const ResetPassword = React.lazy(() => import("./views/Pages/PasswordReset/ForgotPassword"));
const ForgotPassword = React.lazy(() =>
  import("./views/Pages/PasswordReset/ForgotPassword")
);
const NewPassword = React.lazy(() =>
  import("./views/Pages/PasswordReset/NewPassword")
);
const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));

const Checklist = React.lazy(() => import("./views/Checklists/Checklist2"));

const PublicParameters = React.lazy(() =>
  import("./views/PublicParameters/PublicParameters")
);

const App = () => {
  // console.log(localUser);

  const DebugRouter = ({ children }) => {
    const { location } = useHistory();
    if (process.env.NODE_ENV === "development") {
      console.log(
        `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(
          location.state
        )}`
      );
    }
    // if not logged on, store the route in local storage and go there after login is completed

    return children;
  };

  return (
    <BrowserRouter>
      <React.Suspense fallback={loading()}>
        <AuthContextProvider>
          <DebugRouter>
            {/* <Switch> */}
            {/* <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/newPassword/:userId/:token"
                name="Reset Password"
                render={(props) => {
                  return <NewPassword {...props} />;
                }}
              /> */}
            {!localUser.token && (
              <Switch>
                <Route
                  path="/"
                  name="Home"
                  render={(props) => <UnAuthenticatedLayout {...props} />}
                />
              </Switch>
            )}
            {localUser.token && (
              <Switch>
                <Route
                  exact
                  path="/publicParameters/:abId"
                  name="Public Parameters"
                  render={(props) => {
                    // *** this route is here because I want to see public parameters even if I'm logged on
                    return <PublicParameters {...props} />;
                  }}
                />
                <Route
                  exact
                  path="/checklist/:labId/:auditNo/:id"
                  name="Checklist"
                  render={(props) => <Checklist {...props} />}
                />
                <Route
                  path="/"
                  name="Home"
                  render={(props) => <AuthenticatedLayout {...props} />}
                />
              </Switch>
            )}
            {/* </Switch> */}
          </DebugRouter>
          {/* {!localUser.token && <Redirect from="*" to="/login" exact />} */}
        </AuthContextProvider>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;