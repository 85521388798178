const defaultDevelopmentConfig = {
    apiBaseUrl: "http://localhost:4100/graphql",
    environment: "Development"
};

const defaultProdConfig = {
    apiBaseUrl: "https://gql.ab.qamanager.com/graphql",
    environment: "Production"
};

const defaultConfig = process.env.NODE_ENV === "production" ? defaultProdConfig : defaultDevelopmentConfig;

const dynamicConfigUrl = "/config.json";

let config = null;

export const configLoadingErrorMessage =
  "Error while fetching global config, the App will not be rendered. (This is NOT a React error.)";

// Load configuration from the dynamicConfigUrl
export async function loadConfig() {
    if (config) return config; // Prevent multiple fetches
    const response = await fetch(dynamicConfigUrl)
        .then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then((data) => {
            config = data;
            if (process.env.NODE_ENV === "development") {
                console.debug("Global config fetched: ", data);
            }
        })
        .catch((e) => {
            if (process.env.NODE_ENV === "development") {
                console.warn(
                    `Failed to load global configuration from '${dynamicConfigUrl}', using the default configuration instead:`,
                    defaultConfig
                );
                config = defaultConfig;
            } else {
                console.error(
                    configLoadingErrorMessage,
                    `Have you provided the config file '${dynamicConfigUrl}'?`,
                    e
                );
            }
        });
    return config;
}

// Get configuration in any file
export function getConfig() {
    if (!config) {
        throw new Error("Config has not been loaded yet.");
    }
    return config;
}