import gql from "graphql-tag";

export const LOCATIONS = gql`
  query locations($abId: ID!, $labId: ID!) {
    locations(abId: $abId, id: $labId) {
      _id
      locationName
      address1
      address2
      city
      state
      zip
      county
      country
    }
  }
`;

export const STAGED_LAB_APPLICATION = gql`
  query stagedLabApplication($abId: ID!) {
    stagedLabApplication(abId: $abId) {
      name
      epaNumber
      labPhone
      pabName
      labTypeName
      labCode
      nelapId
      accountNumber
      hoursOfOperation
      website
      publicLab
      labId
      labName
      fileName
      currentLabDetails {
        name
        epaNumber
        labPhone
        primaryAb
        pabName
        type
        labTypeName
        labCode
        nelapId
        accountNumber
        hoursOfOperation
        website
        publicLab
      }
      locations {
        locationType
        address1
        address2
        city
        state
        zip
        phone
      }
      positions {
        title
        firstName
        lastName
        email
        phone
        primary
        startDate
        endDate
        notes
        positionId
      }
      parameters {
        selected
        abParamId
        program
        methodCode
        method
        revision
        revisionDate
        analyteCode
        analyte
        matrix
        current
      }
      currentLocations {
        locationType
        address1
        address2
        city
        state
        zip
        phone
      }
      currentPositions {
        title
        firstName
        lastName
        email
        phone
        primary
        startDate
        endDate
        notes
      }
    }
  }
`;

export const PT_USER_STAGED_DATA = gql`
  query ptUserStagedData($abId: ID!) {
    ptUserStagedData(abId: $abId) {
      fileName
      fileSize
      stagedDate
      validationErrors
      stagedData {
        _id
        studyType
        validStudyType
        studyNumber
        validStudyNumber
        studyShipDate
        validShipDate
        studyCloseDate
        validCloseDate
        studyAmendDate
        analyteCode
        analyteName
        validAnalyte
        methodCode
        methodName
        validMethod
        result
        resultId
        validResult
        laboratory
        labId
        labName
        validLaboratory
        providerCode
        providerId
        validProvider
        matrix
        matrixId
        validMatrix
      }
    }
  }
`;

export const AB_ANALYTES = gql`
  query abAnalytes($abId: ID!) {
    abAnalytes(abId: $abId) {
      id
      abCode
      analyte
      casNumber
      type
      active
      paramCount
      labCount
    }
  }
`;

export const AB_METHODS = gql`
  query abMethods($abId: ID!) {
    abMethods(abId: $abId) {
      id
      abCode
      method
      revision
      revisionDate
      name
      technologyId
      technology
      title
      active
      paramCount
      labCount
    }
  }
`;

export const TNI_METHODS = gql`
  query tniMethods($abId: ID!) {
    tniMethods(abId: $abId) {
      tniCode
      method
      revision
      revisionDate
      name
      technology
      title
      active
      alias
      templateId
      template
      templateActive
    }
  }
`;

export const SELECT_AUDIT_PARTICIPANTS_AB = gql`
  query selectAuditParticipantsAB($abId: ID!, $labId: ID!, $auditNo: ID!) {
    selectAuditParticipantsAB(abId: $abId, labId: $labId, auditNo: $auditNo) {
      participantId
      firstName
      lastName
      title
    }
  }
`;

export const SELECT_AUDIT_PARTICIPANTS_LAB = gql`
  query selectAuditParticipantsLab($abId: ID!, $labId: ID!, $auditNo: ID!) {
    selectAuditParticipantsLab(abId: $abId, labId: $labId, auditNo: $auditNo) {
      participantId
      firstName
      lastName
      title
    }
  }
`;

export const AUDIT_TYPES = gql`
  query getAuditTypes($abId: ID!) {
    auditTypes(abId: $abId) {
      _id
      abId
      auditType
      description
      auditFrequency
      milestones {
        milestoneId
        milestoneName
        masterSeq
        typeSeq
        milestoneUserId
        milestoneLeadMonths
      }
    }
  }
`;

export const AUDIT = gql`
  query getAudit($abId: ID!, $labId: ID!, $id: ID!) {
    audit(abId: $abId, labId: $labId, auditId: $id) {
      _id
      abId
      labId
      name
      auditNo
      auditTypeId
      auditType
      auditFrequency
      auditTitle
      auditStartDate
      auditCompleteDate
      auditEndDate
      assessorID
      firstName
      lastName
      auditComplete
      notes
      auditDueBy
      auditScheduleDate
      firstLetterSent
      firstCaReceived
      appReceived
      appProcessed
      appDueDate
      appComplete
      partOne
      partTwo
      upsTracking
      cycleClosed
      dueBy
      carAccepted
      expNoticeSent
      ptReviewDate
      reviewerID
      milestones {
        auditNo
        milestoneSequence
        milestoneName
        milestoneLeadMonths
        completedDate
        notes
      }
      documentId
      futureAudits {
        auditNo
        auditTitle
        auditDueBy
        documentId
      }
    }
  }
`;

export const GET_AUDIT_CORRESPONDENCE = gql`
  query auditCorrespondence($abId: ID!, $labId: ID!, $auditNo: ID!) {
    auditCorrespondence(abId: $abId, labId: $labId, auditNo: $auditNo) {
      _id
      type
      createDate
      issueDate
      issuedBy
      issuedByName
      notes
      base64pdf
    }
  }
`;

export const GET_REPORTDEF = gql`
  query getReportDef {
    getReportDef {
      _id
      text1Name
      text1Json
      ext2Name
      text2Json
      text3Name
      text3Json
      image1Name
      image1Json
      image1Data
      image2Name
      image2Json
      image2Data
      image3Name
      image3Json
      image3Data
    }
  }
`;

export const GET_REPORTDEF_AB = gql`
  query getReportDefAb($abId: ID!, $reportType: String!) {
    getReportDefAb(abId: $abId, reportType: $reportType)
  }
`;

export const GET_REPORTS = gql`
  query getReports($abId: ID!) {
    getReports(abId: $abId) {
      _id
      reportType
      reportName
      reportTitle
      reportDescription
      defaultVersion
    }
  }
`;

export const GET_AB_IMAGES = gql`
  query abImages($abId: ID!, $imageType: Int!) {
    abImages(abId: $abId, imageType: $imageType) {
      _id
      name
    }
  }
`;

export const TECHNOLOGIES = gql`
  query getTechnologies {
    technologies {
      _id
      name
      description
      discipline
    }
  }
`;

export const FOPT = gql`
  query foPt($abId: ID!) {
    foPt(abId: $abId) {
      analyteCode
      matrixId
      implementDate
      analyteName
      matrixName
    }
  }
`;

export const GET_CHECKLIST_FINDINGS = gql`
  query checklistFindings($abId: ID!, $labId: ID!, $auditNo: ID!) {
    checklistFindings(abId: $abId, labId: $labId, auditNo: $auditNo) {
      checklistId
      sequence
      text
      questionResponse
      typeOption
      notes
      methodCode
      methodName
    }
  }
`;

export const GET_CHECKLIST = gql`
  query getChecklist($abId: ID!, $checklistId: ID!) {
    getChecklist(abId: $abId, checklistId: $checklistId) {
      title
      createDate
      enteredBy
      enteredByName
      methodCode
      methodName
      notes
      templateName
      checklistDetail {
        _id
        sequence
        type
        typeId
        text
        reference
        requirement
        textResponse
        questionResponse
        typeOption
        notes
        checklistMedia {
          _id
          dtlSequence
          createDate
          fileName
          fileType
          fileSize
          base64File
        }
      }
    }
  }
`;

export const TEMPLATES = gql`
  query templates($abId: ID!) {
    templates(abId: $abId) {
      _id
      createDate
      createdBy
      title
      description
      notes
      uploadedBy
      uploadedByName
      uploadDate
      active
    }
  }
`;

export const TEMPLATE = gql`
  query template($abId: ID!, $hdrId: ID!) {
    template(abId: $abId, hdrId: $hdrId) {
      _id
      sequence
      type
      typeId
      text
      typeOption
    }
  }
`;

export const GET_TEMPLATE = gql`
  query getTemplate($abId: ID!, $_id: ID!) {
    getTemplate(abId: $abId, _id: $_id) {
      _id
      createDate
      createdBy
      title
      description
      notes
      uploadedBy
      uploadedByName
      uploadDate
      active
    }
  }
`;

export const LAB_INVOICE1 = gql`
  query labInvoice1($abId: ID!, $labId: ID!, $invoiceId: ID!) {
    labInvoice1(abId: $abId, labId: $labId, invoiceId: $invoiceId) {
      methodCode
      methodName
      matrix
      matrixName
      programId
      programName
      feeId
      feeName
      feeDescription
      fee
      analyteCount
      total
    }
  }
`;

export const LAB_INVOICE2 = gql`
  query labInvoice2($abId: ID!, $labId: ID!, $invoiceId: ID!) {
    labInvoice2(abId: $abId, labId: $labId, invoiceId: $invoiceId) {
      analyteCode
      analyteName
      matrix
      matrixName
      programId
      programName
      feeId
      feeName
      feeDescription
      fee
      methodCount
    }
  }
`;

export const LAB_INVOICE_METHOD = gql`
  query labInvoiceMethod(
    $abId: ID!
    $labId: ID!
    $invoiceId: ID!
    $status: Int!
  ) {
    labInvoiceMethod(
      abId: $abId
      labId: $labId
      invoiceId: $invoiceId
      status: $status
    ) {
      _id
      feeName
      fee
      baseFeeNum
      additionalFee
      maxFee
      count
      numBase
      numAddl
      subTotal
      proratedAmt
    }
  }
`;

export const LAB_INVOICE_ANALYTE = gql`
  query labInvoiceAnalyte(
    $abId: ID!
    $labId: ID!
    $invoiceId: ID!
    $status: Int!
  ) {
    labInvoiceAnalyte(
      abId: $abId
      labId: $labId
      invoiceId: $invoiceId
      status: $status
    ) {
      _id
      feeName
      fee
      baseFeeNum
      additionalFee
      maxFee
      count
      numBase
      numAddl
      subTotal
      proratedAmt
    }
  }
`;

export const LABPARAMETERS2 = gql`
  query getLabParameters2($abId: ID!, $labId: ID!) {
    labParameters2(abId: $abId, labId: $labId, isDone: true) {
      _id
      abId
      abParamId
      methodCode
      method
      analyteCode
      analyte
      matrixId
      matrixName
      programId
      programName
      programDesc
      technology
      abStatus
      startDate
      endDate
      primaryAb
      abName
      code
      revision
      revisionDate
      ptRequired
      ptGrade
      approved
      isDone
      accredStatus
      notes
      AMonths
      BMonths
      CMonths
      ADays
      BDays
      CDays
      score1
      score2
      score3
      overallScore
      lastCloseDate
      foptAnalyte
      active
    }
  }
`;

export const PT_RESULTS2 = gql`
  query ptResults2(
    $runQry: Boolean!
    $abId: ID!
    $labId: ID!
    $years: Int!
    $activeStatus: Int
    $result: Int
  ) {
    ptResults2(
      runQry: $runQry
      abId: $abId
      labId: $labId
      years: $years
      activeStatus: $activeStatus
      result: $result
    ) {
      _id
      labIdNo
      labName
      providerId
      providerName
      studyType
      studyNumber
      studyShipDate
      studyCloseDate
      analyteCode
      analyte
      methodCode
      method
      technology
      matrixId
      matrix
      resultId
      resultName
      fileName
      fileSize
      processDate
      firstName
      lastName
      activeResult
      changeDate
      userName
      notes
    }
  }
`;
export const PT_RESULTS = gql`
  query ptResults($abId: ID!, $labId: ID!) {
    ptResults(abId: $abId, labId: $labId) {
      providerId
      providerName
      studyType
      studyNumber
      studyShipDate
      studyCloseDate
      analyteCode
      analyte
      methodCode
      method
      technology
      matrixId
      matrix
      resultId
      resultName
    }
  }
`;

export const PT_ACTIONS = gql`
  query ptActions($abId: ID!) {
    ptActions(abId: $abId) {
      actionId
      labId
      labName
      providerCode
      providerName
      studyType
      studyNumber
      studyShipDate
      studyCloseDate
      studyNumber
      methodCode
      method
      methodName
      matrixId
      matrixName
      fileName
      dateSent
      dateReceived
      ptProcessDate
      status
      leadAssessor
    }
  }
`;

export const PT_ACTION_ANALYTES = gql`
  query ptActionAnalytes($abId: ID!) {
    ptActionAnalytes(abId: $abId) {
      actionId
      labId
      labName
      providerCode
      providerName
      studyType
      studyNumber
      studyShipDate
      studyCloseDate
      studyNumber
      methodCode
      method
      methodName
      analyteCode
      analyte
      ptProcessDate
    }
  }
`;

export const PT_INTERVAL_EXCEPTIONS = gql`
  query ptIntervalExceptions($abId: ID!) {
    ptIntervalExceptions(abId: $abId) {
      _id
      exception
      labId
      lab
      analyteCode
      analyte
      matrixId
      matrix
      technology
      studyNumber1
      studyNumber2
      studyNumber3
      studyType1
      studyType2
      studyType3
      providerId1
      providerId2
      providerId3
      providerName1
      providerName2
      providerName3
      studyShipDate1
      studyShipDate2
      studyShipDate3
      studyCloseDate1
      studyCloseDate2
      studyCloseDate3
      close1ToShip2
      close2ToShip3
      methodCode
      method
      revision
      revisionDate
      fileName
      processDate
      activeResult
      changeDate
      userId
      userName
      notes
    }
  }
`;

export const MILESTONE_CONFIG = gql`
  query milestoneConfig($abId: ID!, $auditTypeId: ID!) {
    milestoneConfig(abId: $abId, auditTypeId: $auditTypeId) {
      _id
      milestoneSequence
      milestoneName
      milestoneLeadMonths
    }
  }
`;

export const MILESTONE_MASTER = gql`
  query milestoneMaster($abId: ID!) {
    milestoneMaster(abId: $abId) {
      _id
      milestoneSequence
      milestoneName
      milestoneUserId
      userName
    }
  }
`;

export const MILESTONE_DATA = gql`
  query milestoneData($abId: ID!, $auditNo: ID!) {
    milestoneData(abId: $abId, auditNo: $auditNo) {
      completed_1
      completed_2
      completed_3
      completed_4
      completed_5
      completed_6
      completed_7
      completed_8
      completed_9
      completed_10
      notes_1
      notes_2
      notes_3
      notes_4
      notes_5
      notes_6
      notes_7
      notes_8
      notes_9
      notes_10
    }
  }
`;

export const AB_FEES = gql`
  query abParamFees($abId: ID!) {
    abParamFees(abId: $abId) {
      _id
      feeName
      description
      fee
      feeType
      additionalFee
      maxFee
      baseFeeNum
    }
  }
`;
export const GET_AUDITORS = gql`
  query auditors($abId: ID!) {
    auditors(abId: $abId) {
      userId
      fullName
    }
  }
`;
export const AUDIT_MILESTONES = gql`
  query auditMilestones($abId: ID!) {
    auditMilestones(abId: $abId) {
      row_num
      labIdNo
      labName
      auditTitle
      auditNo
      documentId
      auditTypeId
      auditType
      auditDueBy
      milestoneSequence
      milestoneName
      milestoneLeadMonths
      milestoneUserId
      personResponsible
      targetDate
      completedDate
      completeDateA
      notes
      milestoneId
    }
  }
`;
export const MILESTONE_STATUS = gql`
  query milestoneStatus($abId: ID!) {
    milestoneStatus(abId: $abId) {
      milestoneSeq {
        milestoneName
        milestoneSequence
      }
      dataset1 {
        milestoneName
        milestoneSequence
        mCount
      }
      dataset2 {
        milestoneName
        milestoneSequence
        mCount
      }
      dataset3 {
        milestoneName
        milestoneSequence
        mCount
      }
    }
  }
`;
export const GET_AB = gql`
  query ab($id: ID!) {
    ab(id: $id) {
      number
      code
      name
      longName
      address
      lat
      lng
      hdrImageId
      certHdrImg
      useProgram
      ppSearchText
    }
  }
`;

export const GET_AUDIT_DATA = gql`
  query auditData($abId: ID!, $labId: ID!, $auditNo: ID!) {
    auditData(abId: $abId, labId: $labId, auditNo: $auditNo) {
      abName
      longName
      labName
      contactName
      address1
      city
      state
      zip
      printDate
      auditStartDate
      auditCompleteDate
      leadAssessor
      assessorTitle
      assessorEmail
      headerImage
      footerImage
      reportData {
        section1Json
        section2Json
        section3Json
        headerImage
        footerImage
      }
      abParticipants
      labParticipants
      findings {
        reference
        methodName
        requirement
        comments
      }
      recommendations {
        reference
        methodName
        requirement
        comments
      }
    }
  }
`;
export const GET_AUDIT_FINAL_RESULT = gql`
  query auditFinalResult($abId: ID!, $labId: ID!, $auditNo: ID!) {
    auditFinalResult(abId: $abId, labId: $labId, auditNo: $auditNo) {
      _id
      checklist
      reference
      requirement
      answer
      findingType
      comments
      otherNotes
    }
  }
`;
export const CHECKLIST_REQUIREMENTS = gql`
  query checklistRequirements($abId: ID!) {
    checklistRequirements(abId: $abId) {
      checklistNo
      tniCode
      method
      revision
      reference
      requirement
    }
  }
`;
export const CHECKLIST_QUESTIONS = gql`
  query checklistQuestions($abId: ID!) {
    checklistQuestions(abId: $abId) {
      hdrId
      category
      notes
      detailId
      requirement
      otherDetails
    }
  }
`;
export const AUDIT_CHECKLISTS = gql`
  query auditChecklists($abId: ID!, $labId: ID!, $auditNo: ID!) {
    auditChecklists(abId: $abId, labId: $labId, auditNo: $auditNo) {
      labId
      auditNo
      checklistType
      methodId
      method
      template
      checklistId
      progress
    }
  }
`;
export const METHOD_CHECKLISTS = gql`
  query methodChecklists($abId: ID!, $labId: ID!, $auditNo: ID!) {
    methodChecklists(abId: $abId, labId: $labId, auditNo: $auditNo) {
      _id
      labId
      auditNo
      methodCode
      method
      technology
      name
      templateId
      templateName
      checklistId
      checklistName
      assignedTo
      assignedToName
      progress
    }
  }
`;
export const ADHOC_CHECKLISTS = gql`
  query adhocChecklists($abId: ID!, $labId: ID!, $auditNo: ID!) {
    adhocChecklists(abId: $abId, labId: $labId, auditNo: $auditNo) {
      _id
      labId
      auditNo
      title
      templateId
      templateName
      checklistId
      checklistName
      assignedTo
      assignedToName
      progress
    }
  }
`;
export const CHECKLISTS_DEFINITIONS = gql`
  query checklistDefinitions($abId: ID!, $labId: ID!, $auditNo: ID!) {
    checklistDefinitions(abId: $abId, labId: $labId, auditNo: $auditNo) {
      _id
      title
      templateId
      templateName
      templateActive
      checklistId
      assignedTo
      assignedToName
      defaultTemplateId
      defaultTemplateTitle
      methodCode
      progress
    }
  }
`;
export const PT_LABS = gql`
  query ptLabs($abId: ID!) {
    ptLabs(abId: $abId) {
      labId
      name
    }
  }
`;

export const PT_EVAL_1 = gql`
  query ptEvaluation1($abId: ID!, $labId: Int) {
    ptEvaluation1(abId: $abId, labId: $labId) {
      analyteCode
      analyte
      matrixId
      matrix
      technology
      methodCode
      method
      providerId1
      provider1Name
      providerId2
      provider2Name
      providerId3
      provider3Name
      studyType1
      studyType2
      studyType3
      studyNumber1
      studyNumber2
      studyNumber3
      studyShipDate1
      studyShipDate2
      studyShipDate3
      studyCloseDate1
      studyCloseDate2
      studyCloseDate3
      overall1
      overall2
      overall3
      study1Score
      study2Score
      study3Score
      study1a
      study1b
      study1c
      study1d
      study2a
      study2b
      study2c
      study2d
      study3a
      study3b
      study1aCount
      study1bCount
      study1cCount
      study1dCount
      study2aCount
      study2bCount
      study2cCount
      study2dCount
      study3aCount
      study3bCount
      ptScore
      AMonths
      BMonths
      CMonths
      ADays
      BDays
      CDays
      score1
      score2
      score3
      score1Source
      score2Source
      score3Source
      overallScore
      foptAnalyte
    }
  }
`;

export const PT_EVAL_2 = gql`
  query ptEvaluation2($abId: ID!, $labId: Int) {
    ptEvaluation2(abId: $abId, labId: $labId) {
      analyteCode
      analyte
      matrix
      technology
      methodCode
      method
      providerId1
      provider1Name
      providerId2
      provider2Name
      providerId3
      provider3Name
      studyType1
      studyType2
      studyType3
      studyNumber1
      studyNumber2
      studyNumber3
      studyShipDate1
      studyShipDate2
      studyShipDate3
      studyCloseDate1
      studyCloseDate2
      studyCloseDate3
      study1Score
      study2Score
      study3Score
      AMonths
      BMonths
      CMonths
      ADays
      BDays
      CDays
    }
  }
`;

export const GET_AUDIT_FINDINGS = gql`
  query auditFindings($abId: ID!, $labId: ID!, $auditNo: ID!) {
    auditFindings(abId: $abId, labId: $labId, auditNo: $auditNo) {
      tniCode
      method
      requirement
      reference
      question
      notes
      yes
      no
      na
      ne
      answer
      recommend
      positiveFinding
      findingType
      otherNotes
      labResponse
      furtherAction
      furtherActionNote
      assessorName
      resultId
    }
  }
`;

export const GOOGLE_GET_FOLDER_FILES = gql`
  query googleGetFolderFiles($abId: ID, $folderName: String) {
    googleGetFolderFiles(abId: $abId, folderName: $folderName) {
      key
      modified
      size
    }
  }
`;

export const GOOGLE_IMG_URL = gql`
  query googleImgUrl($abId: ID!, $fileName: String!) {
    googleImgUrl(abId: $abId, fileName: $fileName)
  }
`;

export const GOOGLE_IMAGES = gql`
  query googleImages($abId: ID!) {
    googleImages(abId: $abId) {
      fileName
      fileSize
      dateCreated
    }
  }
`;

export const AB_USERS = gql`
  query abUsers($abId: ID!) {
    abUsers(abId: $abId) {
      id
      firstName
      lastName
      email
      role
      assessor
      title
      mobilePhone
      active
      authenticated
    }
  }
`;

export const USER_LOGINS = gql`
  query userLogins {
    userLogins {
      userId
      abId
      abName
      firstName
      lastName
      email
      role
      loginDtTime
    }
  }
`;

export const GET_LAB_POSITIONS = gql`
  query positions($abId: ID!) {
    positions(abId: $abId) {
      _id
      position
      rank
    }
  }
`;

export const GET_LAB_KEY_POSITIONS = gql`
  query labKeyPositions($abId: ID!, $labId: ID!) {
    labKeyPositions(abId: $abId, labId: $labId) {
      _id
      contactId
      positionId
      startDate
      endDate
      position
      rank
      firstName
      lastName
      email
      phone
      primary
      active
    }
  }
`;

export const GET_LAB_KEY_POSITIONS2 = gql`
  query labKeyPositions2($abId: ID!, $labId: ID!) {
    labKeyPositions2(abId: $abId, labId: $labId) {
      _id
      contactId
      positionId
      startDate
      endDate
      position
      rank
      firstName
      lastName
      email
      phone
      primary
      active
      notes
    }
  }
`;

export const GET_LAB_TYPES = gql`
  query labTypes($abId: ID!) {
    labTypes(abId: $abId) {
      _id
      labType
    }
  }
`;

export const GET_LAB_PARAM_APPROVED = gql`
  query labParamApprovedXlat($abId: ID!) {
    labParamApprovedXlat(abId: $abId) {
      _id
      abId
      approvedText
      approvedValue
    }
  }
`;

export const LABS_GEO = gql`
  query getLabsGeoLocation($abId: ID!) {
    labsGeoLocation(abId: $abId) {
      labId
      lat
      lng
      name
      website
    }
  }
`;
export const DASHBOARD_LABS = gql`
  query getDashboardLabs($abId: ID!) {
    dashboardLabs(abId: $abId) {
      _id
      labIdNo
      labCode
      epaNumber
      accountNumber
      fieldLab
      dmrqaLab
      name
      type
      taxId
      active
      isPrimary
      isSecondary
      primaryAb
      nelapId
      hoursOfOperation
      leadAuditor
      auditorName
      initialAccred
      accredStart
      accredEnd
      lastCertAccredStart
      lastCertAccredEnd
      daysToDue
      notes
      publicLab
      stateAccredOnly
      useAltPoc
      ownerType
      labType
      website
      directionsToLab
      paramCount
      geo
      auditNo
      auditTitle
      milestones {
        completedDate
      }
    }
  }
`;
export const LABS = gql`
  query getLabs($abId: ID!) {
    labs(abId: $abId) {
      _id
      labIdNo
      labCode
      epaNumber
      accountNumber
      fieldLab
      dmrqaLab
      name
      type
      taxId
      active
      isPrimary
      isSecondary
      primaryAb
      primaryAbName
      primaryAbCode
      nelapId
      hoursOfOperation
      leadAuditor
      auditorName
      initialAccred
      accredStart
      accredEnd
      lastCertAccredStart
      lastCertAccredEnd
      daysToDue
      notes
      publicLab
      stateAccredOnly
      useAltPoc
      ownerType
      labType
      labTypeName
      website
      directionsToLab
      paramCount
      geo
      certSuppInfo
      primaryFirstName
      primaryLastName
      primaryEmail
      multipleLocations
      drinkingWaterLab
    }
  }
`;

export const GET_LAB = gql`
  query getLab($abId: ID!, $id: ID!) {
    lab(abId: $abId, id: $id) {
      _id
      abName
      abLongName
      labIdNo
      labCode
      epaNumber
      accountNumber
      fieldLab
      dmrqaLab
      multipleLocations
      drinkingWaterLab
      name
      shortName
      type
      taxId
      active
      isPrimary
      isSecondary
      primaryAb
      pabName
      pabLongName
      nelapId
      hoursOfOperation
      leadAuditor
      leadAuditorName
      initialAccred
      accredStart
      accredEnd
      notes
      publicLab
      stateAccredOnly
      useAltPoc
      ownerType
      labType
      labTypeName
      website
      directionsToLab
      certSuppInfo
      labPhone
      labLocations {
        _id
        locationName
        address1
        address2
        city
        county
        state
        zip
        country
        attention
        lat
        lng
      }
      labContacts {
        _id
        firstName
        lastName
        email
        phone
        positionId
        position
        nameTitle
        primary
        active
        effDate
        endDate
        notes
      }
      labKeyPositions {
        _id
        contactId
        positionId
        startDate
        endDate
        notes
        title
        rank
        firstName
        lastName
        email
        phone
        primary
        active
      }
    }
  }
`;

export const GET_PARAM_LABS = gql`
  query GET_PARAM_LABS($abId: ID!, $abParamId: ID!) {
    abParamLabs(abId: $abId, abParamId: $abParamId) {
      labId
      name
      epaNumber
      labCode
      address1
      city
      state
      zip
      active
    }
  }
`;

export const GET_USER = gql`
  query GET_USER($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      userId
      email
      firstName
      lastName
      abId
      abName
      role
      labId
      refreshToken
      token
      tokenExpiration
      expireTime
    }
  }
`;

export const ALL_ABS = gql`
  query abs {
    abs {
      number
      code
      name
    }
  }
`;

export const ALL_ISSUES = gql`
  query issues {
    issues {
      _id
      title
      issueType
      detail
      richDetail
      resolution
      priority
      status
      dateEntered
      dateDue
      lastUpdated
      enteredBy
      assignedTo
    }
  }
`;

export const ALL_POSITIONS = gql`
  query positions($abId: ID!) {
    positions(abId: $abId) {
      _id
      abId
      position
      rank
    }
  }
`;

export const SNAPSHOT_HDR = gql`
  query snapshotHdr($abId: ID!, $labId: ID!) {
    snapshotHdr(abId: $abId, labId: $labId) {
      _id
      documentId
      createDate
      createdBy
      createdByName
      issueDate
      issuedBy
      issuedByName
      labId
      labName
      epaNumber
      notes
      accredStart
      accredEnd
      detailCount
      base64pdf
    }
  }
`;

export const GET_CERTIFICATE_DATA = gql`
  query certificateData($abId: ID!, $labId: ID!, $_id: ID) {
    certificateData(abId: $abId, labId: $labId, _id: $_id) {
      abName
      labName
      epaNumber
      labCode
      accredStart
      accredEnd
      address
      city
      state
      zip
      certDetail {
        matrixName
        matrixCode
        programName
        programCode
        methodName
        methodCode
        revision
        revisionDate
        technology
        analyteName
        analyteCode
        startDate
        endDate
        primaryAb
        abParamId
      }
    }
  }
`;

export const GET_CERTIFICATE_RPT = gql`
  query certificateRpt($abId: ID!, $labId: ID!, $_id: ID) {
    certificateRpt(abId: $abId, labId: $labId, _id: $_id) {
      abName
      labName
      epaNumber
      labCode
      accredStart
      accredEnd
      address
      city
      state
      zip
      issueDate
      issuedBy
      issuedByName
      issuedByTitle
      certDetail {
        matrixName
        matrixCode
        programName
        programCode
        methodName
        methodCode
        revision
        revisionDate
        technology
        analyteName
        analyteCode
        startDate
        endDate
        primaryAb
        abParamId
      }
      params {
        matrixName
        programName
        methods {
          name
          methodCode
          analytes {
            name
            analyteCode
            startDate
            endDate
            primaryAb
          }
        }
      }
    }
  }
`;

export const ABPARAMETERS = gql`
  query getAbParameters($abId: ID!) {
    abParameters(abId: $abId) {
      _id
      abParamId
      abId
      methodCode
      method
      activeMethod
      revision
      revisionDate
      analyteCode
      analyte
      activeAnalyte
      analyteType
      matrixId
      matrixName
      programId
      programName
      programDesc
      technology
      status
      feeId
      feeName
      active
      labCount
    }
  }
`;

export const DISTINCTMETHODS = gql`
  query getDistinctMethods($abId: ID!) {
    distinctMethods(abId: $abId) {
      tniCode
      method
      technology
      revision
      paramCount
    }
  }
`;

export const DISTINCT_ANALYTES = gql`
  query getDistinctAnalytes(
    $abId: ID
    $methodCode: ID
    $matrixId: ID
    $programId: ID
  ) {
    distinctAnalytes(
      abId: $abId
      methodCode: $methodCode
      matrixId: $matrixId
      programId: $programId
    ) {
      tniCode
      analyte
      type
      foPt
      paramCount
    }
  }
`;

export const PARAM_METHOD_MATRICES = gql`
  query getParamMethodMatrices($abId: ID!, $methodCode: ID) {
    paramMethodMatrices(abId: $abId, methodCode: $methodCode) {
      _id
      name
      shortName
      paramCount
    }
  }
`;

export const PARAM_METHOD_PROGRAMS = gql`
  query paramMethodPrograms($abId: ID!, $methodCode: ID) {
    paramMethodPrograms(abId: $abId, methodCode: $methodCode) {
      _id
      name
      description
      paramCount
    }
  }
`;

export const INVOICES = gql`
  query getInvoices($abId: ID!, $labId: ID!) {
    invoices(abId: $abId, labId: $labId) {
      _id
      abId
      labId
      labName
      invoiceNumber
      description
      issueDate
      dueDate
      numMonths
      issuedBy
      issuedByName
      paidDate
      notes
      status
      totalAmount
      base64Pdf
    }
  }
`;

export const INVOICE = gql`
  query getInvoice($abId: ID!, $labId: ID!, $id: ID!) {
    invoice(abId: $abId, labId: $labId, invoiceId: $id) {
      _id
      abId
      labId
      labName
      invoiceNumber
      description
      issueDate
      dueDate
      numMonths
      issuedBy
      paidDate
      notes
      status
      base64Pdf
      invoiceParameters {
        abParamId
        paramExists
        matrixName
        programName
        programDesc
        methodCode
        method
        methodName
        revision
        analyteCode
        analyte
        startDate
        approved
        accredStatus
      }
    }
  }
`;

export const INVOICE_FEES = gql`
  query getInvoiceFees($abId: ID!, $labId: ID!, $invoiceId: ID!) {
    invoiceFees(abId: $abId, labId: $labId, invoiceId: $invoiceId) {
      _id
      abId
      labId
      description
      amount
    }
  }
`;

export const INVOICE_FEE_CONFIG = gql`
  query getInvoiceFeeConfig($abId: ID!) {
    invoiceFeeConfig(abId: $abId) {
      _id
      description
      amount
    }
  }
`;

export const EXTERNAL_LINKS = gql`
  query externalLinksRead($abId: ID!) {
    externalLinksRead(abId: $abId) {
      _id
      title
      description
      url
      sequence
    }
  }
`;

export const PP_COLUMN_DISPLAY = gql`
  query ppColumnDisplay($abId: ID!) {
    ppColumnDisplay(abId: $abId) {
      _id
      seq
      columnName
      columnText
      visible
    }
  }
`;

export const PUBLIC_PARAMETERS = gql`
  query publicParameters($abId: ID!, $criteria: String) {
    publicParameters(abId: $abId, criteria: $criteria) {
      abName
      abShortName
      abImage
      parameters {
        labIdNo
        labName
        city
        state
        address
        address2
        zip
        country
        contactFirstName
        contactLastName
        contactEmail
        labPhone
        labFax
        epaNumber
        labCode
        isPrimary
        accredStart
        accredEnd
        matrixName
        programName
        methodName
        methodCode
        analyteName
        analyteCode
        abParamId
        approvedText
        active
        commercial
        labType
        labTypeName
        createDate
      }
    }
  }
`;

export const MATRIX_DATA = gql`
  query matrixData {
    matrixData {
      _id
      name
      shortName
    }
  }
`;

export const WORD_TEMPLATES = gql`
  query wordTemplates($abId: ID!, $reportType: Int) {
    wordTemplates(abId: $abId, reportType: $reportType) {
      _id
      name
      description
      reportType
      dateUploaded
      uploadedBy
      fileName
      defaultTemplate
      templateB64
    }
  }
`;

export const ASSESSMENT_IMAGES = gql`
  query assessmentImages($abId: ID!, $labId: ID!, $auditNo: ID!) {
    assessmentImages(abId: $abId, labId: $labId, auditNo: $auditNo) {
      _id
      title
      text
      fileName
      fileType
      fileSize
      base64File
    }
  }
`;